export const cermoneData = 
    [
        "Serrure 1 pt",
        "Telecommande",
        "Boitier a Clef",
        "Bouton Poussoir",
        "Serrure 3 pts",
        "Verrou",
        "Cremone",
        "Locqueteau"
    ]


    export const vitrageData =
    [
        "6 mm Claire",
    "8 mm Claire",
    "6 mm Tintee",
    "8 mm Tintee",
    "33,1 Opale",
    "33,1 Claire",
    "33,1 Tintee",
    "44,2 Claire",
    "44,2 Tintee",
    "Lame 61 Extr",
    "Lame Pleine",
    "RAVENALE",
    "ORIENTALE",
    "6 mm Imprimée",
    "Panneau DECO"
    ]